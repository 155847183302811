import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subscription, switchMap } from 'rxjs';

import { RouteObserverService } from '@/services/route-observer.service';
import { AgencesService } from '@/services/agences.service';
import { ContentService } from '@/services/content.service';
import { Agence, FooterItem, FooterPage } from '@/models';
import { MainRoutes } from '@/constants';

type PageType = 'agence' | 'annonce';

// AXEPTIO cookies acceptation function (loaded via external script)
declare const openAxeptioCookies: any;

const URL_OPEN_AGENCY = 'https://www.franchise-era-immobilier.com/ouvrir-une-agence';
const ERA_COPYRIGHT = '2024 - ERA Immobilier - ';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit, OnDestroy {

  public purchaseItems: FooterItem[] = [
    { path: '/achat', key: 'property_purchase' },
    { path: '/achat-appartement', key: 'buy apartment' },
    { path: '/achat-maison', key: 'buy house' },
    { path: '/achat-terrain', key: 'buy land' },
    { path: '/achat-programmes-neufs', key: 'buy new program' },
    { path: '/achat-immeuble', key: 'buy building' },
    { path: '/achat-parking-garage', key: 'buy parking garage' }
  ];

  public rentalItems: FooterItem[] = [
    { path: '/location', key: 'property_rental' },
    { path: '/location-appartement', key: 'rent apartment' },
    { path: '/location-maison', key: 'rent house' },
    { path: '/location-parking-garage', key: 'rent parking garage' }
  ];

  public speakingItems: FooterItem[] = [];
  public labelItems: FooterItem[] = [];
  public faqItems: FooterItem[] = [];
  public showLegalNotice = false;
  public pageType?: PageType;
  public agence?: Agence;

  private subscriptions = new Subscription();
  private footerPages: FooterPage[] = [];
  private semanticTitle = '';
  private isGroupe = false;
  private isAgence = false;

  constructor(
    private routeObserver: RouteObserverService,
    private contentService: ContentService,
    private agencesService: AgencesService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.subscriptions.add(
      this.contentService.getContent().pipe(
        switchMap((data) => {
          this.semanticTitle = data.master_semantique?.titre;
          this.footerPages = data.footer_pages;

          return combineLatest([
            this.routeObserver.currentRoute$,
            this.agencesService.agence$
          ]);
        })
      ).subscribe(([route, agence]) => {
        const pageType: PageType = (route.type === MainRoutes.Agences) ? 'agence' : 'annonce';
        const { data, type } = agence ?? {};
        const isGroupe = (type === 'groupe');

        if ((this.pageType !== pageType) || (this.isGroupe !== isGroupe) || (this.agence?.id !== data?.id)) {
          this.isGroupe = type === 'groupe';
          this.isAgence = type === 'agence';
          this.pageType = pageType;
          this.agence = data;

          this.buildFooterColumns();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private buildFooterColumns(): void {
    const agenceFooter = this.isAgence || this.isGroupe;

    this.speakingItems = this.buildSpeakingColumn(agenceFooter);
    this.faqItems = this.buildFaqColumn(agenceFooter && !!this.agence);
    this.labelItems = agenceFooter ? this.buildLabelColumn() : [];

    this.cd.markForCheck();
  }

  private buildSpeakingColumn(agenceFooter: boolean): FooterItem[] {
    const showAgenceFooter = agenceFooter && this.agence && (this.agence.statut !== 2);

    const items: FooterItem[] = [];

    if (!agenceFooter) {
      items.push({ path: MainRoutes.Recrutement, key: 'recruit' });
    } else if (showAgenceFooter) {
      items.push({ path: [this.isGroupe ? MainRoutes.Groupes : '/', this.agence.slug, 'emploi-immobilier'], key: 'recruit' });
    }

    items.push({ href: URL_OPEN_AGENCY, key: 'open_agency' });
    items.push({ path: MainRoutes.Agences, key: 'FOOTER.OUR_AGENCIES' });

    if (showAgenceFooter) {
      items.push({ path: [this.isGroupe ? MainRoutes.Groupes : '/', this.agence.slug, 'contact'], key: 'contact_us' });
    }

    if (!agenceFooter) {
      items.push({ path: MainRoutes.Contact, key: 'contact_us' });
    }

    if (this.semanticTitle) {
      items.push({ path: MainRoutes.Guide, title: this.semanticTitle });
    }

    items.push({ href: URL_OPEN_AGENCY, key: 'choose ERA real estate' });
    items.push({ path: MainRoutes.Effectifs, key: 'findteam' });

    for (const page of this.footerPages) {
      items.push({ path: [MainRoutes.Metiers, page.slug], title: page.titre });
    }

    return items;
  }

  private buildLabelColumn(): FooterItem[] {
    const items: FooterItem[] = [];

    if (this.agence) {
      if (this.agence.is_club_manager) {
        items.push({ img: '/assets/imgs/label_club_manager.jpeg', key: 'label' });
      }
      if (this.agence.is_club_manager_premium) {
        items.push({ img: '/assets/imgs/label_club_manager_premium.jpeg', key: 'label' });
      }
      if (this.agence.is_qualite_service) {
        items.push({ img: '/assets/imgs/label_qualite.png', key: 'label' });
      }
    }

    return items;
  }

  private buildFaqColumn(legalNotice: boolean): FooterItem[] {
    const items: FooterItem[] = [
      { label: ERA_COPYRIGHT, key: 'all rigths reserved' },
      { path: MainRoutes.SitePlan, key: 'plan_site' },
      { path: MainRoutes.Faqs, key: 'faq' },
      { path: MainRoutes.MentionsLegales, key: 'mentions' },
      { path: MainRoutes.PersonalData, key: 'personal_data' }
    ];

    if (legalNotice) {
      items.push({ click: () => this.showLegalNotice = !this.showLegalNotice, key: 'legal_notice_agency' });
    }

    items.push({ click: () => this.openCookiesModal(), key: 'cookie_preference' });
    items.push({ path: MainRoutes.ToutesNosAgences, key: 'FOOTER.ALL_AGENCIES' });

    return items;
  }

  private openCookiesModal(): void {
    if (typeof openAxeptioCookies === 'function') {
      openAxeptioCookies();
    }
  }
}
