import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';

export class CustomRouteReuseStategy implements RouteReuseStrategy {

  shouldDetach(_route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  store(_route: ActivatedRouteSnapshot, _detachedTree: DetachedRouteHandle): void {
  }

  shouldAttach(_route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  retrieve(_route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }

  /**
   * Checks if the route should be reused ( AchatComponent, parent and children ) in order not to reload the whole page
   * @param future The next activated route
   * @param curr The current activated route
   * @returns true if we can reuse the same component, else false.
   */
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    while (curr.firstChild)
      curr = curr.firstChild;

    while (future.firstChild)
      future = future.firstChild;

    return (future.component === curr.component) && (future.data['shouldReuse'] === true);
  }
}
