import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@/modules/shared/shared.module';
import { UiModule } from '@/modules/ui/ui.module';

import { ForgotComponent } from './components/forgot/forgot.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { ResetComponent } from './components/reset/reset.component';
import { ValidateComponent } from './components/validate/validate.component';
import { FacebookComponent } from './components/login/facebook/facebook.component';
import { GoogleComponent } from './components/login/google/google.component';

@NgModule({
  declarations: [
    FacebookComponent,
    GoogleComponent,
    ForgotComponent,
    LoginComponent,
    RegisterComponent,
    ResetComponent,
    ValidateComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    UiModule
  ],
  exports: [
    ForgotComponent,
    LoginComponent,
    RegisterComponent,
    ResetComponent,
    ValidateComponent
  ]
})
export class AuthModule { }
