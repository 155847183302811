import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { FormBudgetFilters } from '@/models';

@Component({
  selector: 'app-budget-filter',
  templateUrl: './budget-filter.component.html',
  styleUrls: ['./budget-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => BudgetFilterComponent),
    multi: true
  }]
})
export class BudgetFilterComponent implements ControlValueAccessor {
  @Input() totalItems!: number;

  @Output() closed = new EventEmitter();
  @Output() validate = new EventEmitter();
  @Output() reinit = new EventEmitter<FormBudgetFilters>();

  public min: string;
  public max: string;

  private onChange: any = () => {};
  private onTouched: any = () => {};

  handleChange(field: 'min' | 'max', value: string): void {
    this[field] = value;
    this.onChange({
      prix_from: this.min.replaceAll(' ', ''),
      prix_to: this.max.replaceAll(' ', '')
    });
    this.onTouched();
  }

  handleReset(): void {
    this.reinit.emit({ prix_from: '', prix_to: '' });
  }

  handleSubmit(): void {
    this.validate.emit();
  }

  handleClose(): void {
    this.closed.emit();
  }

  writeValue(value: FormBudgetFilters): void {
    this.min = value.prix_from;
    this.max = value.prix_to;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
