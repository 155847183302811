import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  styleUrl: './filter-dropdown.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterDropdownComponent {
  @Input() buttonTitle?: string;
  @Input() contentTitle?: string;
  @Input() modalTitle?: string;
  @Input() iconName?: string;
  @Input() filterSet = false;
  @Input() totalItems = 0;
  @Input() contentHeight = '';
  @Input() contentScroll = false;

  @Output() filterClosed = new EventEmitter();
  @Output() filterSubmit = new EventEmitter();
  @Output() filterReset = new EventEmitter();

  public showModal = false;

  toggleModal(): void {
    this.showModal = !this.showModal;
    if (!this.showModal) {
      this.filterClosed.emit();
    }
  }

  handleCancel(): void {
    if (this.showModal) {
      this.showModal = false;
      this.filterClosed.emit();
    }
  }

  handleReset(): void {
    this.showModal = false;
    this.filterReset.emit();
  }

  handleSubmit(): void {
    this.showModal = false;
    this.filterSubmit.emit();
  }
}
