<app-modal-container>
	<div class="modal-need-to-connect">
		<div class="w-full flex justify-between content-center">
			<div class="title">
				<h3 class="display-h3-28px">{{ 'add property to favorites' | transloco }}</h3>
			</div>
			<p (click)="onClose()">
				<span class="icon-close"></span>
			</p>
		</div>
		<div class="text">
			<h2 class="mb-30px">
				{{ 'to bookmark ads, please create an account or log in' | transloco }}.
			</h2>
			<p>{{ 'with your MyERA account you can also' | transloco }} :</p>
			<p>- {{ 'create you alerts' | transloco }}</p>
			<p>- {{ 'follow the sale of your property' | transloco }}</p>
		</div>
		<app-button text="to login" size="large" (click)="onConnect()" />
	</div>
</app-modal-container>
