import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { FormCriteresFilters } from '@/models';
import { CRITERES } from '@/constants';

@Component({
  selector: 'app-criteres-filter',
  templateUrl: './criteres-filter.component.html',
  styleUrls: ['./criteres-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CriteresFilterComponent),
    multi: true
  }]
})
export class CriteresFilterComponent implements ControlValueAccessor {
  @Input() totalItems!: number;

  @Output() closed = new EventEmitter();
  @Output() validate = new EventEmitter();
  @Output() reinit = new EventEmitter<FormCriteresFilters>();

  public CRITERES = CRITERES;
  public criteres: boolean[] = [];
  public selectedCount = 0;
  public buttonText = '';

  private onChange: any = () => {};
  private onTouched: any = () => {};

  critereChange(index: number, value: boolean): void {
    this.criteres[index] = value;
    this.updateButtonText();
    this.update();
  }

  handleReset(): void {
    this.reinit.emit({ criteres: [] });
  }

  handleSubmit(): void {
    this.validate.emit();
  }

  handleClose(): void {
    this.closed.emit();
  }

  writeValue(value: FormCriteresFilters): void {
    this.criteres = this.CRITERES.map((critere) => (
      value.criteres.includes(critere.value)
    ));

    this.updateButtonText();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private update(): void {
    const criteres = this.CRITERES.filter((_, i) => this.criteres[i]).map((c) => c.value);

    this.onChange({ criteres });
    this.onTouched();
  }

  private updateButtonText(): void {
    const firtSelected = this.criteres.findIndex((c) => c);
    this.buttonText = firtSelected >= 0 ? this.CRITERES[firtSelected].key : undefined;
    this.selectedCount = this.criteres.filter((c) => c).length;
  }
}
