import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';

import { FormSurfaceFilters } from '@/models';

@Component({
  selector: 'app-surface-filter',
  templateUrl: './surface-filter.component.html',
  styleUrls: ['./surface-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SurfaceFilterComponent),
    multi: true
  }]
})
export class SurfaceFilterComponent implements ControlValueAccessor {
  @Input() totalItems!: number;

  @Output() closed = new EventEmitter();
  @Output() validate = new EventEmitter();
  @Output() reinit = new EventEmitter<FormSurfaceFilters>();

  public surfaceFrom: string;
  public surfaceTo: string;
  public terrainFrom: string;
  public terrainTo: string;

  private onChange: any = () => {};
  private onTouched: any = () => {};

  handleChange(field: string, value: boolean): void {
    this[field] = value;
    this.update();
  }

  update(): void {
    this.onChange({
      surface_from: this.surfaceFrom.replaceAll(' ', ''),
      surface_to: this.surfaceTo.replaceAll(' ', ''),
      terrain_from: this.terrainFrom.replaceAll(' ', ''),
      terrain_to: this.terrainTo.replaceAll(' ', '')
    });
    this.onTouched();
  }

  handleReset(): void {
    this.reinit.emit({
      surface_from: '',
      surface_to: '',
      terrain_from: '',
      terrain_to: ''
    });
  }

  handleSubmit(): void {
    this.validate.emit();
  }

  handleClose(): void {
    this.closed.emit();
  }

  writeValue(value: FormSurfaceFilters): void {
    this.surfaceFrom = value.surface_from;
    this.surfaceTo = value.surface_to;
    this.terrainFrom = value.terrain_from;
    this.terrainTo = value.terrain_to;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
