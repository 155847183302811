import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-modal-alert-need-to-connect',
  templateUrl: './modal-alert-need-to-connect.component.html',
  styleUrls: ['./modal-alert-need-to-connect.component.scss']
})
export class ModalAlertNeedToConnectComponent {
  @Output() closeModal = new EventEmitter();
  @Output() openLogin = new EventEmitter();

  onClose() {
    this.closeModal.emit();
  }

  onConnect() {
    this.openLogin.emit();
  }
}
