<app-modal-container>
  <div class="modal-forgot">
    <div class="w-full flex justify-between content-center">
      <div class="title">
        <p (click)="getBack()"><span class="icon-arrow-left"></span></p>
        <h3 class="display-h3-28px">{{ 'Forgot password' | transloco }}</h3>
      </div>
      <p (click)="onClose()"><span class="icon-close"></span></p>
    </div>

    @if (done) {
      <div class="submitted-message">
        <h4>{{ 'will receive mail to reset password' | transloco }}</h4>
        <p class="">{{ 'check spam mailbox' | transloco }}</p>

        <app-button text="send again email" type="button" size="large" (click)="onSubmit()" />
      </div>
    }
    @else {
      <form [formGroup]="forgotForm" (submit)="onSubmit()">
        <div class="text">
          {{ 'Forgot password' | transloco }}
        </div>

        <app-form-input id="email" label="email" icon="icon-email" formControlName="email" [validationErrors]="submitted" />

        <app-button-loading
          [disabled]="forgotForm.invalid && submitted"
          [loading]="loading"
          text="send again email"
        />
      </form>
    }
  </div>
</app-modal-container>
