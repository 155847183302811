import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchAutocompleteStoreService {
  public searchByCityId = new BehaviorSubject('');
  public searchByDepartmentId = new BehaviorSubject('');
  public cityDepSearchedInputValue = new BehaviorSubject('');
  public searchDepOrCity = new BehaviorSubject('');

  public autocompleteCities = new BehaviorSubject([]);
  public autocompleteDepartments = new BehaviorSubject([]);
  public showAutocompleteSearch = new BehaviorSubject(false);
  public showAutocompleteSearchOnClick = new BehaviorSubject(false);

  sharedautocompleteCities = this.autocompleteCities.asObservable();
  sharedautocompleteDepartments = this.autocompleteDepartments.asObservable();
  sharedshowAutocompleteSearch = this.showAutocompleteSearch.asObservable();
  sharedshowAutocompleteSearchOnClick = this.showAutocompleteSearchOnClick.asObservable();
  sharedSearchDepOrCity = this.searchDepOrCity.asObservable();

  sharedSearchedByCityId = this.searchByCityId.asObservable();
  sharedSearchedByDepartmentId = this.searchByDepartmentId.asObservable();
  sharedCityDepSearchedInputValue = this.cityDepSearchedInputValue.asObservable();
}
