import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-facebook',
  templateUrl: './facebook.component.html',
  styleUrls: ['./facebook.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FacebookComponent {
  @Output() connect = new EventEmitter<'facebook'>();

  submitLogin(): void {
    this.connect.emit('facebook');
  }
}
