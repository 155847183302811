import { APP_INITIALIZER } from '@angular/core';
import { GoogleTagManagerConfiguration } from 'angular-google-tag-manager';
import { tap } from 'rxjs';

import { ConfigService } from '@/services/config.service';

/**
 * Retrieve application external config json file
 * @param configService The configuration service instance
 * @returns An observable
 */
export const getAppConfig = (configService: ConfigService) => () => (
  configService.loadConfig()
);

/**
 * Wait for configuration retrival before configuring GTM
 * @param gtmc The GTM config manager instance
 * @returns An observable
 */
export const configureGoogleTagManager = (configService: ConfigService, gtmc: GoogleTagManagerConfiguration) => () => (
  configService.config$.pipe(
    tap((config) => {
      console.debug('GTM: setting googleTagManagerId to', config.gtm);
      gtmc.set({ id: config.gtm });
    })
  )
);

export const appInitializers = [{
  provide: APP_INITIALIZER,
  deps: [ConfigService],
  multi: true,
  useFactory: getAppConfig
}, {
  provide: APP_INITIALIZER,
  deps: [ConfigService, GoogleTagManagerConfiguration],
  multi: true,
  useFactory: configureGoogleTagManager
}];
