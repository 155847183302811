import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Toast } from '../../models/toast';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastComponent {
  @Input() toast: Toast;
  @Input() index = 0;

  @Output() remove = new EventEmitter<number>();

  handleClose(): void {
    this.remove.emit(this.toast.id);
  }
}
