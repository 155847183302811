import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ToastContainerComponent } from './components/toast-container/toast-container.component';
import { ToastComponent } from './components/toast/toast.component';
import { ToastService } from './services/toast.service';

@NgModule({
  declarations: [
    ToastComponent,
    ToastContainerComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ToastContainerComponent
  ],
  providers: [
    ToastService
  ]
})
export class ToastModule { }
