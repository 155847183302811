import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AlertService } from '@/services/alert.service';
import { AuthService } from '@/services/auth.service';
import { PASSWORD_VALIDATION_PATTERN } from '@/constants';

import { CustomValidators } from '../../validators/CustomValidators';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegisterComponent implements OnDestroy {
  @Output() closeModal = new EventEmitter();
  @Output() validate = new EventEmitter();
  @Output() back = new EventEmitter();

  private subscriptions = new Subscription();

  public submitted = false;
  public loading = false;
  public messageError = false;
  public done = false;

  public registerForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    nom: new FormControl('', [Validators.required]),
    prenom: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required, Validators.pattern(PASSWORD_VALIDATION_PATTERN)]),
    password_confirmation: new FormControl('', [Validators.required]),
    contact_agreement: new FormControl(false),
    partner_agreement: new FormControl(false)
  }, [
    CustomValidators.mustMatch('password', 'password_confirmation')
  ]);

  constructor(
    private router: Router,
    private authService: AuthService,
    private alertService: AlertService,
    private cd: ChangeDetectorRef
  ) {
    if (this.authService.isConnected) {
      this.router.navigate(['/']);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get email() {
    return this.registerForm.get('email') as FormControl;
  }

  get nom() {
    return this.registerForm.get('nom') as FormControl;
  }

  get prenom() {
    return this.registerForm.get('prenom') as FormControl;
  }

  get password() {
    return this.registerForm.get('password') as FormControl;
  }

  get confirmation() {
    return this.registerForm.get('password_confirmation') as FormControl;
  }

  onClose() {
    this.closeModal.emit();
  }

  onBack() {
    this.back.emit();
  }

  onSubmit() {
    this.submitted = true;

    if (this.registerForm.valid) {
      this.loading = true;

      this.subscriptions.add(
        this.authService.register(this.registerForm.value).subscribe({
          next: (data: any) => {
            this.loading = false;
            if (data.errors) {
              this.messageError = true;
            } else {
              this.alertService.success('Registration successful', true);
              this.closeModal.emit();
              this.validate.emit();
              this.done = true;
            }
            this.cd.markForCheck();
          },
          error: (error) => {
            this.alertService.error(error);
            this.loading = false;
            this.cd.markForCheck();
          }
        })
      );
    }
  }
}
