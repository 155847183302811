import { provideTransloco, TranslocoModule } from '@jsverse/transloco';
import { NgModule } from '@angular/core';

import { TranslocoHttpLoader } from './transloco-loader';
import { translocoPreLoad } from './transloco-preload';
import { translocoConfig } from './transloco-config';

@NgModule({
  exports: [
    TranslocoModule
  ],
  providers: [
    translocoPreLoad,
    provideTransloco({
      config: translocoConfig,
      loader: TranslocoHttpLoader
    })
  ]
})
export class TranslocoRootModule { }
