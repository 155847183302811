import { ChangeDetectionStrategy, Component, OnInit, signal } from '@angular/core';

import { ToastService } from '../../services/toast.service';
import { Toast } from '../../models/toast';

@Component({
  selector: 'app-toast-container',
  templateUrl: './toast-container.component.html',
  styleUrl: './toast-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastContainerComponent implements OnInit {
  public toasts = signal<Toast[]>([]);

  constructor(private toastService: ToastService) {}

  ngOnInit(): void {
    this.toastService.toast$.subscribe((toast) => {
      this.toasts.update((toasts) => [toast, ...toasts]);
      if (toast.delay) {
        setTimeout(() => this.remove(toast.id), toast.delay);
      }
    });
  }

  remove(id: number): void {
    this.toasts.update((toasts) => toasts.filter((t) => t.id !== id));
  }
}
