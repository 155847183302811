import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { HeaderService } from '@/services/header.service';
import { AuthService } from '@/services/auth.service';
import { MainRoutes } from '@/constants';
import { ConnectedUser } from '@/models';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class LoginComponent implements OnDestroy {
  @Output() closeModal = new EventEmitter();
  @Output() openForgot = new EventEmitter();
  @Output() openRegister = new EventEmitter();

  private subscriptions = new Subscription();

  public loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', Validators.required)
  });

  public deactivatedAccount = false;
  public returnUrl: string;
  public submitted = false;
  public loading = false;
  public message = '';

  constructor(
    private router: Router,
    private authService: AuthService,
    private headerService: HeaderService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get email() {
    return this.loginForm.get('email') as FormControl;
  }

  get password() {
    return this.loginForm.get('password') as FormControl;
  }

  handleClose(): void {
    this.closeModal.emit(true);
  }

  handleRegister(): void {
    this.openRegister.emit();
  }

  handleForgotPassword(): void {
    this.openForgot.emit();
  }

  onSubmit(mode?: 'google' | 'facebook'): void {
    this.submitted = true;
    this.message = '';

    if ((mode !== undefined) || this.loginForm.valid) {
      let request: Observable<ConnectedUser>;

      if (mode === 'google') {
        request = this.authService.loginGo();
      } else if (mode === 'facebook') {
        request = this.authService.loginFb();
      } else {
        const { email, password } = this.loginForm.value;
        request = this.authService.login(email, password);
      }

      this.subscriptions.add(
        request.subscribe({
          next: () => {
            this.loading = false;

            const redirectUrl = this.headerService.headerState.redirectUrl;
            if (redirectUrl) {
              this.router.navigateByUrl(redirectUrl);
            } else {
              this.router.navigate([MainRoutes.Profile]);
            }
            this.closeModal.emit(true);

            this.cd.markForCheck();
          },
          error: (error) => {
            this.loading = false;

            if (error.message === 'deactivated') {
              this.deactivatedAccount = true;
            } else if (mode === undefined) {
              this.message = 'Connection failed';
            } else {
              this.message = 'log_in_another_way';
            }

            this.cd.markForCheck();
          }
        })
      );
    }
  }
}
