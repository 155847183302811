<app-modal-container>
	<div class="modal-validate">
		<div class="w-full flex justify-between content-center">
			<div class="title">
				<h3 class="display-h3-28px">{{ 'registration in progress' | transloco }}</h3>
			</div>
			<p (click)="onClose()">
				<span class="icon-close"></span>
			</p>
		</div>

		<div class="text">
			<p>{{ 'allow to connect' | transloco }}</p>
		</div>

		<button class="btn btn-arrow-red-dark w-full" type="submit" (click)="onModalLogin()">
			{{ 'to login' | transloco }}
		</button>
	</div>
</app-modal-container>
