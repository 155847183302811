<form class="filters" [formGroup]="form">
	@let itemsCount = totalItems();

	<app-location-filter
		[mode]="searchMode"
		[totalItems]="itemsCount"
		formControlName="location"
		(validate)="handleValidate()"
		(reinit)="handleReset($event)"
	/>

	@if (searchMode === 'annonces') {
		<app-type-filter
			[totalItems]="itemsCount"
			formControlName="type"
			(validate)="handleValidate()"
			(reinit)="handleReset($event)"
		/>

		<app-budget-filter
			[totalItems]="itemsCount"
			formControlName="budget"
			(validate)="handleValidate()"
			(reinit)="handleReset($event)"
		/>

		<app-surface-filter
			[totalItems]="itemsCount"
			formControlName="surface"
			(validate)="handleValidate()"
			(reinit)="handleReset($event)"
		/>

		<app-pieces-filter
			[totalItems]="itemsCount"
			formControlName="pieces"
			(validate)="handleValidate()"
			(reinit)="handleReset($event)"
		/>

		<app-criteres-filter
			[totalItems]="itemsCount"
			formControlName="criteres"
			(validate)="handleValidate()"
			(reinit)="handleReset($event)"
		/>
	}

</form>
