<div class="container w-full h-screen p-120px flex space-around items-center not-found">

	<div class="flex flex-col mr-80px">
		<h1 class="display-h1-regular">404</h1>

		@if (content_404) {
			<p class="display-text-16px mr-50px mb-40px" [innerHtml]="content_404"></p>
		} @else {
			<p class="display-text-16px mr-50px mb-40px">
 				{{ 'page not found' | transloco }}
			</p>
		}

		<app-button link="/" text="back home" />
	</div>

	<img src="/assets/imgs/404.png" alt="" />
</div>
