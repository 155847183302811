@let more = ('6_and_more' | transloco);

<app-filter-dropdown
	buttonTitle="rooms"
	contentTitle="number_rooms"
	iconName="icon-home"
	[filterSet]="selectedChambres || selectedPieces"
	[totalItems]="totalItems"
	(filterSubmit)="handleSubmit()"
	(filterReset)="handleReset()"
	(filterClose)="handleClose()"
>
	<span filterButton>
		@if (selectedPieces || selectedChambres) {
			{{ !selectedPieces ? '' : pieces[5] ? more : textPieces + 'p' }}
			{{ selectedPieces && selectedChambres ? ', ' : '' }}
			{{ !selectedChambres ? '' : chambres[5] ? more : textChambres + 'ch' }}
		}
	</span>

	<div filterContent>
		<div class="rooms">
			<label *ngFor="let n of allRooms; let i = index">
				<input class="hidden" type="checkbox" [ngModel]="pieces[i]" (ngModelChange)="piecesChange(i, $event)" />
				<span>{{ i === 5 ? more : n }}</span>
			</label>
		</div>

		<span class="modal-dropdown-text mt-40px">
			{{ 'number_bedrooms' | transloco }}
		</span>
		<div class="rooms mb-40px">
			<label *ngFor="let n of allRooms; let i = index">
				<input class="hidden" type="checkbox" [ngModel]="chambres[i]" (ngModelChange)="chambresChange(i, $event)" />
				<span>{{ i === 5 ? more : n }}</span>
			</label>
		</div>
	</div>

</app-filter-dropdown>