import { Translation, TranslocoLoader } from '@jsverse/transloco';
import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { filter, switchMap } from 'rxjs';

import { ConfigService } from '@/services/config.service';

@Injectable({
  providedIn: 'root'
})
export class TranslocoHttpLoader implements TranslocoLoader {
  private configService = inject(ConfigService);
  private http = inject(HttpClient);

  getTranslation(lang: string) {
    return this.configService.config$.pipe(
      filter((config) => !!config),
      switchMap((config) => (
        this.http.get<Translation>(`${config.baseUrl}/assets/i18n/${lang}.json`)
      ))
    );
  }
}
