import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, Subscription, switchMap } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { SearchAutocompleteStoreService } from '@/services//search-auto-complete.service';
import { StorageKey, StorageService } from '@/services/storage.service';
import { GeographyService } from '@/services/geography.service';
import { AgencesService } from '@/services/agences.service';
import { VendreService } from '@/services/vendre.service';
import { FilterService } from '@/services/filter.service';
import { GeolocService } from '@/services/geoloc.service';
import { MainRoutes, SEARCH_DEBOUNCE, SEARCH_MIN_LENGTH } from '@/constants';

import {
  Agence, AgenceGroupe, FilterTypeAnnonce, SearchLocation, SearchMode, SavedSearchType, SearchFilters,
  GeolocSearch, SearchGeoAutoCompletion, GeolocFeature
} from '@/models';

@Component({
  selector: 'app-search-bar-mobile',
  templateUrl: './search-bar-mobile.component.html',
  styleUrls: ['./search-bar-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchBarMobileComponent implements OnInit, OnDestroy {
  @Output() foundGeoCoder = new EventEmitter<any>();

  @Input() agenceGroupe?: AgenceGroupe;
  @Input() agence?: Agence;

  private subscriptions = new Subscription();
  private searchMode: SearchMode = 'annonces';

  public searchType: FilterTypeAnnonce = 'vente';
  public inputAddress = new FormControl('');
  public closeAutocomplete = false;
  public address_name: string;
  public city_name: string;
  public city_zip: number;
  public geometry: any;
  public postcode: number;
  public autocompleteCities: SearchLocation[] = [];
  public autocompleteDepartments: SearchLocation[] = [];
  public autocompleteAddresses: GeolocFeature[] = [];
  public lastSearch: string[] = undefined;
  public submitDisabled = false;

  constructor(
    private router: Router,
    private vendreService: VendreService,
    private agencesService: AgencesService,
    private geolocService: GeolocService,
    private searchAutocompleteStoreService: SearchAutocompleteStoreService,
    private geographyService: GeographyService,
    private filterService: FilterService,
    private storageService: StorageService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    //const geoCoder = this.activatedRoute.snapshot.paramMap.get(this.geoCoder);

    this.subscriptions.add(
      this.searchAutocompleteStoreService.sharedautocompleteCities.subscribe((res) => {
        this.autocompleteCities = res;
        this.cd.markForCheck();
      })
    );

    this.subscriptions.add(
      this.searchAutocompleteStoreService.sharedautocompleteDepartments.subscribe((res) => {
        this.autocompleteDepartments = res;
        this.cd.markForCheck();
      })
    );

    this.subscriptions.add(
      this.inputAddress.valueChanges.pipe(
        filter((value) => value?.length >= SEARCH_MIN_LENGTH),
        distinctUntilChanged(),
        debounceTime(SEARCH_DEBOUNCE),
        switchMap((query) => {
          return (this.searchType === 'estimer') ?
            this.geographyService.getAddressAutocomplete(query) :
            this.geographyService.getAllAutoComplete(query);
        })
      ).subscribe((data) => {
        this.processReceivedLocations(data);
        this.closeAutocomplete = true;
        this.cd.markForCheck();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onSubmitButton(): void {
    if ((this.searchType === 'estimer') && this.inputAddress.value) {
      this.geolocService.updateGeoCoder(this.inputAddress.value);

      this.vendreService.address_name = this.address_name;
      this.vendreService.city_zip = this.city_zip;
      this.vendreService.city_name = this.city_name;
      this.vendreService.postcode = this.postcode;

      const params: any = { fragment: 'step_2' };

      if (this.agenceGroupe) {
        params.queryParams = { agence_id: this.agenceGroupe.agences[0].id };
      } else if (this.agence) {
        params.queryParams = { agence_id: this.agence.id };
      }

      this.router.navigate([MainRoutes.Estimer], params);
    }
  }

  handleAutocompleteAddress(address: any): void {
    this.getAddressToInput(
      address.properties.name,
      address.properties.postcode,
      address.properties.city,
      address.properties.citycode
    );

    this.geolocService.searchAddressAutocomplete();
    this.getCoordinate(address.geometry);
    this.setAddress(address.properties.label);
  }

  setAddress(address: string): void {
    this.inputAddress.setValue(address, { emitEvent: false });
  }

  closeAutoComplete(): void {
    this.closeAutocomplete = false;
  }

  select(locationItem: SearchLocation): void {
    this.closeAutoComplete();
    this.setAddress('');

    this.filterService.updateFilters(this.searchMode, { searchLocations: [locationItem], type_annonce: this.searchType });
    this.filterService.buildRouteFromFilters({ mode: this.searchMode });
  }

  getLastSearch(type: SavedSearchType): SearchFilters {
    return (this.storageService.getObject(StorageKey.LastSearch) ?? {})[type];
  }

  lastSearchTextFromParams(type: SavedSearchType): string[] {
    const lastSearch = this.getLastSearch(type);
    const lastSearchText: string[] = [];

    const locations = lastSearch.searchLocations?.map((location) => location.name).join(',');

    lastSearchText.push(locations);

    if (lastSearch.typeBien.length) {
      lastSearchText.push(lastSearch.typeBien.join(','));
    }

    if (lastSearch.nb_pieces) {
      lastSearchText.push(lastSearch.nb_pieces + ' pièces');
    }

    if (lastSearch.surface_from || lastSearch.surface_to) {
      let surfaceText = lastSearch.surface_from ? lastSearch.surface_from : '';

      if (surfaceText.length && lastSearch.surface_to) {
        surfaceText = surfaceText + ' - ' + lastSearch.surface_to;
      }

      if (!surfaceText.length && lastSearch.surface_to) {
        surfaceText = lastSearch.surface_to;
      }

      surfaceText = surfaceText + ' m2';
      lastSearchText.push(surfaceText);
    }

    return lastSearchText;
  }

  goToLastSearch(type: SavedSearchType): void {
    const lastSearch = (this.storageService.getObject(StorageKey.LastSearch) ?? {})[type];
    this.filterService.updateFilters(this.searchMode, lastSearch);
    this.filterService.buildRouteFromFilters({ mode: this.searchMode });
  }

  private processReceivedLocations(data: GeolocSearch | SearchGeoAutoCompletion): void {
    if (this.searchType === 'estimer') {
      const { features } = data as GeolocSearch;
      this.autocompleteAddresses = features;
    } else {
      const { villes, departements } = data as SearchGeoAutoCompletion;
      this.autocompleteDepartments = departements;
      this.autocompleteCities = villes;
    }
  }

  private getAddressToInput(address: string, postcode: number, city_name: string, city_zip: number): void {
    this.address_name = address;
    this.postcode = postcode;
    this.city_name = city_name;
    this.city_zip = city_zip;

    this.closeAutocomplete = false;
    this.submitDisabled = false;
  }

  private getCoordinate({ coordinates }) {
    const [lat, long] = coordinates;

    this.subscriptions.add(
      this.agencesService.getNearestAgency(`${long}%2C${lat}`, 1).subscribe(({ data }) => {
        this.geolocService.updateNearestAgency(data);
        this.cd.markForCheck();
      })
    );
  }
}
