<div class="header header-main" [ngClass]="{ 'header-shadow': headerShadow }">
	<nav (scroll)="onScroll($event)" [ngClass]="{ 'annonce': currentRoute.type === MainRoutes.Annonce }">

		<a routerLink="/" class="{{ bigLogo ? 'bigLogo' : 'smallLogo' }}">
			<img class="header-logo bg-white" src="/assets/imgs/era-logo-national.svg" alt="ERA home page" />
		</a>

		@if (currentRoute.type === MainRoutes.Annonce) {
			<div class="header-mobile">
				<p class="header-close" (click)="goBack()">
					<span class="icon-close"></span>
				</p>
			</div>
		}

		<div class="main-header-menu display-desktop">
			<div class="flex items-center">
				@for (item of links; track item.label) {
					<a [routerLink]="item.link" [routerLinkActive]="linkActive" class="mx-5 font-sfText hover:text-blue1">
						{{ ('HEADER.LINKS.' + item.label) | transloco }}
					</a>
				}
			</div>
		</div>

		<div class="menu-contact flex items-center">
			@if (!searchMode) {
				<!-- HEADER SEARCH BUTTON -->
				<div class="display-mobile cursor-pointer hover:text-blue1">
					<span (click)="toggleSearch($event)" class="button-header icon-search fs-22px"></span>
				</div>
			}

			@if (currentRoute.type === MainRoutes.Annonce) {
				<div class="display-mobile cursor-pointer">
					<p (click)="openSocialShare = !openSocialShare" class="button-mobile">
						<span class="button-header icon-share1"></span>
					</p>
				</div>
			}

			<!-- AGENCIES -->
			<a [routerLink]="MainRoutes.Agences" routerLinkActive="text-blue1" class="font-sfText hover:text-blue1 flex items-center">
				<span class="button-header flat locator">
					<img
						[src]="currentRoute.type === MainRoutes.Agences ? '/assets/imgs/icons/agences.svg' : '/assets/imgs/icons/agences2.svg'"
						class="w-24px h-24px"
						alt="logo"
					/>
				</span>
				<span routerLinkActive="underline" class="display-desktop">
					{{ 'our_agencies' | transloco | titlecase }}
				</span>
			</a>

			<!-- FAVORITES -->
			<a [routerLink]="MainRoutes.Favoris" routerLinkActive="text-blue1" class="font-sfText hover:text-blue1 flex items-center">
				<span class="button-header flat icon-heart-empty fs-24px"></span>
				<span routerLinkActive="underline" class="display-desktop">
					{{ 'my favorites' | transloco }}
				</span>
			</a>

			<!-- PROFILE -->
			<div class="display-desktop">
				@if (connectedUser) {
					<a [routerLink]="MainRoutes.Profile" routerLinkActive="text-blue1" class="font-sfText hover:text-blue1 flex items-center">
						<span class="button-header flat icon-user fs-24px"></span>
						<span routerLinkActive="underline" class="display-desktop">
							{{ connectedUser?.name }}
						</span>
					</a>
				} @else {
					<div class="flex items-center hover:text-blue1 cursor-pointer" (click)="handleLogin()">
						<span class="button-header flat icon-user fs-24px"></span>
						<span class="display-desktop">{{ 'account' | transloco }}</span>
					</div>
				}
			</div>

			<!-- BURGER -->
      <app-burger-menu [connectedUser]="connectedUser" [items]="burgerItems" />
    </div>

		@if (openSocialShare && annonce) {
			@let annonceId = annonce.id;
			<div class="container-shared">
				<div class="network">
					<a href="https://www.facebook.com/sharer/sharer.php?u={{ url_front }}/annonces/{{ annonceId }};src=sdkpreparse" target="_blank" rel="nofollow">
						<span class="item"><img src="/assets/imgs/facebook.svg" alt="facebook" /></span>
					</a>
					<a href="https://twitter.com/share?url={{ url_front }}/annonces/{{ annonceId }}" target="_blank" rel="nofollow">
						<span class="item"><img src="/assets/imgs/twitter.svg" alt="twitter" /></span>
					</a>
					<a href="mailto:?subject=Annonce%20trouv%C3%A9e%20sur%20www.erafrance.com&body={{ url_front }}/annonces/{{ annonceId }}" target="_blank" rel="nofollow">
						<span class="item"><img src="/assets/imgs/mail.svg" alt="mail" /></span>
					</a>
				</div>
				<p (click)="openSocialShare = !openSocialShare" class="network-close">
					<span class="icon-close"></span>
				</p>
			</div>
		}

		<!-- HEADER SEARCH BAR -->
		@if (!searchMode && isMobileSearchOpen) {
			<div class="search-container" (clickOutside)="closeSearch()">
				<div class="cursor-pointer" (click)="toggleSearch($event)">
					<span class="icon-arrow-left"></span>
				</div>
				<app-search-bar-mobile />
			</div>
		}
	</nav>

	<ng-container *ngTemplateOutlet="filtersTemplate" />
</div>
