<div class="modal-active-alert">
	<div class="container">
		<div class="container-header flex mb-15px">
			<h5 class="display-h3-28px">{{ 'active_alert' | transloco }}</h5>
			<div (click)="onCloseActiveAlert()">
				<span class="icon-close closeDrawModal"></span>
			</div>
		</div>
		<div class="container-content">
			@if (connected) {
				<p class="display-text-14px">
					{{ 'activate alert will activate ads' | transloco }}.
				</p>
				<app-button-loading text="validate" type="button" [loading]="isLoading" (click)="activeAlert()" />
			} @else {
				<p class="display-text-14px">
					{{ 'to activate an alert, you must be logged into an account' | transloco }}.
				</p>
				<app-button text="PROFILE.login" (click)="onOpenLogin()" />
			}
		</div>
	</div>
</div>
