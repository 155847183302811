<form (clickOutside)="closeAutoComplete()" autocomplete="off" class="active-search">
	<input
		[formControl]="inputAddress"
		placeholder="Votre recherche"
		name="geoCoder"
		type="text"
	/>

	@if ((searchType !== 'estimer') && closeAutocomplete && (autocompleteCities.length >= 1 || autocompleteDepartments.length >= 1)) {
		<div (clickOutside)="closeAutoComplete()" (click)="closeAutoComplete()" class="autoComplete">

			@if (getLastSearch(searchType)) {
				<div class="last-search mb-20px divide-y">
					<h4 class="display-h4 display-text-16px mb-5px">
						<i class="icon icon-clock"></i> {{ 'last_search' | transloco }}
					</h4>
					<p (click)="goToLastSearch(searchType)" class="flex">
						@for (t of lastSearchTextFromParams(searchType); track t; let last = $last) {
							<span>{{ t }} @if (!last) { <span class="circle-separator"></span> }</span>
						}
					</p>
					<div class="border-separator"></div>
				</div>
			}

			@if (autocompleteCities.length >= 1) {
				<section>
					<p>{{ 'cities' | transloco }}</p>
					@for (city of autocompleteCities | slice: 0:5; track city.id) {
						<div class="suggest" id="autoComp-{{ city.id }}" (click)="select(city)">
							<span class="name">{{ city.name }} <app-text-round-separator /></span>
							<span class="zipcode">({{ city.code }})</span>
						</div>
					}
				</section>
			}

			@if (autocompleteDepartments.length >= 1) {
				<section>
					<p>{{ 'departments' | transloco }}</p>
					@for (dep of autocompleteDepartments | slice: 0:5; track dep.id) {
						<div class="suggest" id="autoComp-{{ autocompleteCities.length + dep.id }}" (click)="select(dep)">
							<span class="name">{{ dep.name }} <app-text-round-separator /></span>
							<span class="zipcode">({{ dep.code }})</span>
						</div>
					}
				</section>
			}
		</div>

	} @else if ((searchType === 'estimer') && closeAutocomplete && (autocompleteAddresses.length > 0)) {

		<div class="input-geoloc" (clickOutside)="closeAutoComplete()">
			<ul>
				@for (address of autocompleteAddresses; track address) {
					<li (click)="handleAutocompleteAddress(address)">
						{{ address.properties.label }}
					</li>
				}
			</ul>
		</div>

	}

	<button type="submit" [disabled]="submitDisabled" (click)="onSubmitButton()">
		<img src="/assets/imgs/loupe.svg" alt="" height="16px" width="16px" />
	</button>
</form>
