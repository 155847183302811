import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@/modules/shared/shared.module';
import { AuthModule } from '@/modules/auth/auth.module';
import { UiModule } from '@/modules/ui/ui.module';

import { HeaderComponent } from './components/header/header/header.component';
import { FooterComponent } from './components/footer/footer/footer.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { FiltersComponent } from './components/header/filters/filters.component';
import { BudgetFilterComponent } from './components/header/filters/budget-filter/budget-filter.component';
import { CriteresFilterComponent } from './components/header/filters/criteres-filter/criteres-filter.component';
import { LocationFilterComponent } from './components/header/filters/location-filter/location-filter.component';
import { PiecesFilterComponent } from './components/header/filters/pieces-filter/pieces-filter.component';
import { SurfaceFilterComponent } from './components/header/filters/surface-filter/surface-filter.component';
import { TypeFilterComponent } from './components/header/filters/type-filter/type-filter.component';
import { SearchBarMobileComponent } from './components/header/search-bar-mobile/search-bar-mobile.component';
import { ModalActiveAlertComponent } from './components/header/modal-active-alert/modal-active-alert.component';
import { ModalAlertNeedToConnectComponent } from './components/header/modal-alert-need-to-connect/modal-alert-need-to-connect.component';
import { FilterDropdownComponent } from './components/header/filters/filter-dropdown/filter-dropdown.component';
import { FooterColumnComponent } from './components/footer/footer-column/footer-column.component';
import { HeaderAgenceComponent } from './components/header/header-agence/header-agence.component';
import { HeaderMainComponent } from './components/header/header-main/header-main.component';
import { BurgerMenuComponent } from './components/header/burger-menu/burger-menu.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    NotFoundComponent,
    FiltersComponent,
    BudgetFilterComponent,
    CriteresFilterComponent,
    LocationFilterComponent,
    PiecesFilterComponent,
    SurfaceFilterComponent,
    TypeFilterComponent,
    SearchBarMobileComponent,
    ModalActiveAlertComponent,
    ModalAlertNeedToConnectComponent,
    FilterDropdownComponent,
    FooterColumnComponent,
    HeaderAgenceComponent,
    HeaderMainComponent,
    BurgerMenuComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AuthModule,
    UiModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    NotFoundComponent
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parent: CoreModule) {
    if (parent) {
      throw new Error('The core module cannot be imported twice!');
    }
  }
}
