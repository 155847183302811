<app-filter-dropdown
  buttonTitle="budget"
  iconName="icon-budget"
  [filterSet]="min || max"
  [totalItems]="totalItems"
  (filterSubmit)="handleSubmit()"
  (filterReset)="handleReset()"
  (filterClose)="handleClose()"
>
  <span filterButton>
    @if (min || max) {
      {{ !min ? '0' : min }} - {{ max }} €
    }
  </span>

  <div filterContent>
    <div class="form-inputs">
      <app-form-input
        [ngModel]="min"
        (ngModelChange)="handleChange('min', $event)"
        [ngClass]="{ transformed: !!min }"
        suffix="&euro;"
        type="number"
        label="min"
      />
      <app-form-input
        [ngModel]="max"
        (ngModelChange)="handleChange('max', $event)"
        [ngClass]="{ transformed: !!max }"
        suffix="&euro;"
        type="number"
        label="max"
      />
    </div>
  </div>
</app-filter-dropdown>
