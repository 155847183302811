import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-google',
  templateUrl: './google.component.html',
  styleUrls: ['./google.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GoogleComponent {
  @Output() connect = new EventEmitter<'google'>();

  submitLogin(): void {
    this.connect.emit('google');
  }
}
