import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-validate',
  templateUrl: './validate.component.html',
  styleUrls: ['./validate.component.scss']
})
export class ValidateComponent {
  @Output() closeModal = new EventEmitter();
  @Output() openLogin = new EventEmitter();

  onModalLogin() {
    this.openLogin.emit();
  }

  onClose() {
    this.closeModal.emit();
  }
}
