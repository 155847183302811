<div class="header header-agence" [ngClass]="{ 'header-shadow': headerShadow  }">
  <nav class="header-agence-top">
    <div class="header-agence-top-left">
      <a routerLink="/" class="display-desktop grey-icon hover:text-blue1">
        <p class="display-text-10px uppercase">
          {{ 'previous' | transloco }} era France
        </p>
      </a>
      <a routerLink="/" class="display-mobile">
        <img src="/assets/imgs/era-logo.svg" class="header-logo" alt="ERA home page" />
      </a>
    </div>

    <div class="header-agence-top-right">
      @if (!searchMode) {
        <!-- HEADER SEARCH BUTTON -->
        <div class="display-mobile cursor-pointer hover:text-blue1">
          <p class="button-header" (click)="toggleSearch()">
            <span class="icon-search fs-22px"></span>
          </p>
        </div>

        <!-- HEADER SEARCH BAR MOBILE -->
        @if (isMobileSearchOpen) {
          <div class="display-mobile mobile-container" (clickOutside)="closeSearch()">
            <div (click)="toggleSearch()"><span class="icon-arrow-left"></span></div>
            <app-search-bar-mobile [agence]="agence" [agenceGroupe]="agenceGroupe" />
          </div>
        }
      }

      <!-- AGENCIES -->
      <a [routerLink]="MainRoutes.Agences" class="display-mobile display-text-15 flex text-grey-medium items-center hover:text-blue1">
        <span class="button-header flat locator">
          <img src="/assets/imgs/icons/agences2.svg" class="w-24px h-24px" alt="Store locator" />
        </span>
      </a>

      <!-- FAVORITES -->
      <a [routerLink]="MainRoutes.Favoris" class="display-text-15 text-grey-medium hover:text-blue1">
        <p class="flex flex-nowrap items-center">
          <span class="button-header flat icon-heart-empty fs-24px"></span>
          <span class="display-desktop">{{ 'my favorites' | transloco }}</span>
        </p>
      </a>

      <!-- PROFILE -->
      @if (connectedUser) {
        <a [routerLink]="MainRoutes.Profile" class="display-desktop display-text-15 text-grey-medium hover:text-blue1">
          <p class="flex flex-nowrap items-center">
            <span class="button-header flat icon-user fs-24px"></span>
            <span>{{ connectedUser?.name }}</span>
          </p>
        </a>
      } @else {
        <div (click)="handleLogin()" class="display-desktop display-text-15 text-grey-medium hover:text-blue1 cursor-pointer">
          <div class="flex flex-nowrap items-center">
            <span class="button-header flat icon-user fs-24px"></span>
            <span>{{ 'account' | transloco }}</span>
          </div>
        </div>
      }

      <app-burger-menu [connectedUser]="connectedUser" [items]="burgerItems" />
    </div>
	</nav>

	<nav class="header-agence-bottom display-desktop">
    <a [routerLink]="agenceHome">
      <img src="/assets/imgs/era-logo.svg" alt="Home" />
    </a>

		<ul class="hidden lg:flex">
      @for (item of agenceLinks; track item.label) {
        <li class="hover:text-blue1">
          <a [routerLink]="[item.link]" [queryParams]="item.queryParams" [routerLinkActive]="linkActive">
            {{ ('HEADER.LINKS.' + item.label) | transloco }}
          </a>
        </li>
      }
		</ul>

		<app-network
			[agence]="agence"
			[agenceGroup]="agenceGroupe"
			[header]="true"
			[pathUrl]="currentRoute.url"
		/>

    @if (agenceGroupe && (currentRoute.type === MainRoutes.Groupes)) {
      <div class="header-agence-button">
        <a [routerLink]="[MainRoutes.Groupes + '/' + agenceGroupe?.slug + '-' + agenceGroupe?.id + '/contact']">
          <img src="/assets/imgs/mail.svg" alt="contact" />
        </a>
      </div>
      <app-button
        [link]="[MainRoutes.Groupes + '/' + agenceGroupe?.slug + '-' + agenceGroupe?.id + '/contact']"
        text="contact_agency"
      />
    } @else if ((agence?.statut !== 2) && currentRoute.url.startsWith('/agence-')) {
      <div class="header-agence-button">
        <a [routerLink]="['/' + agence?.slug + '/contact']">
          <img src="/assets/imgs/mail.svg" alt="contact" />
        </a>
      </div>
      <app-button
        [link]="['/' + agence?.slug] + '/contact'"
        text="contact_agency"
      />
    }
	</nav>

	<ng-container *ngTemplateOutlet="filtersTemplate" />
</div>
