<button (click)="togglePanel()">
  <div class="button-header">
    <img src="/assets/imgs/burger.svg" alt="Burger menu" height="12px" width="16px" />
  </div>
</button>

<div #transition class="menu-transition"></div>

<div class="burger-menu" *ngIf="isOpen" [@slideInOut]>

  <div class="burger-menu-head">

    <div class="account-mobile hidden-lg">
      <div class="lg:flex font-sfText hover:text-blue1 cursor-pointer">
        <div class="flex flex-nowrap items-center">
          <div class="account-mobile-picto capitalize">
            {{ connectedUser ? connectedUser?.name.charAt(0) : '?' }}
          </div>
          <div class="account-mobile-text hover:text-blue1 font-semibold">
            <p class="display-text-20px mb-5px">
              {{ connectedUser ? connectedUser?.name : ('account' | transloco) }}
            </p>
            @if (connectedUser) {
              <a (click)="togglePanel()" [routerLink]="MainRoutes.Profile">
                {{ 'see account' | transloco }}
              </a>
            } @else {
              <a (click)="handleLogin()">
                {{ 'PROFILE.login' | transloco }}
              </a>
            }
          </div>
        </div>
      </div>
    </div>

    <p class="burger-menu-head-close" (click)="togglePanel()">
      <span class="icon-close"></span>
    </p>
  </div>

  @for (section of items; track section; let index = $index) {
    <div class="burger-menu-content" [ngClass]="{ 'hidden-lg': index === 0 }">
      @for (item of section; track item.text) {
        @if (item.link) {
          <a (click)="togglePanel()" [routerLink]="item.link" routerLinkActive="underline text-blue1" class="burger-menu-link hover:text-blue1">
            {{ ('HEADER.LINKS.' + item.text) | transloco }}
          </a>
        } @else if (item.href) {
          <a [href]="item.href" class="burger-menu-link hover:text-blue1" target="_blank">
            {{ ('HEADER.LINKS.' + item.text) | transloco }}
          </a>
        }
      }
    </div>
  }

  <div class="burger-menu-contact hidden-lg">
    <p class="font-sfText display-text-16px">{{ 'find_us' | transloco }} :</p>
    <app-network [header]="true" />
  </div>

  @if (connectedUser) {
    <div class="burger-menu-logout hidden-lg" (click)="handleLogout()">
      <p class="icon icon-log-out mr-14px"></p>
      <p>{{ 'PROFILE.logout' | transloco }}</p>
    </div>
  }
</div>
