<app-modal-container>
	<div class="modal-login">
		<div class="w-full flex justify-between items-center">
			<h3 class="display-h3">{{ 'to login' | transloco }}</h3>
			<p (click)="handleClose()">
				<span class="icon-close"></span>
			</p>
		</div>

		<form [formGroup]="loginForm" (submit)="onSubmit(undefined)">

      @if (message) {
        <div class="login-error">{{ message | transloco }}</div>
      }

			<app-form-input
        id="email"
        label="email"
        icon="icon-email"
        formControlName="email"
        [validationErrors]="submitted"
      />

			<app-form-input
				type="password"
				id="password"
				label="password"
				formControlName="password"
				[validationErrors]="submitted"
			/>

      <div class="cursor-pointer display-text-12px form-detail" (click)="handleForgotPassword()">
        {{ 'Forgot password' | transloco }}
      </div>

      <div class="line">
        <p class="line"></p>
        <p class="display-text-16px">OU</p>
      </div>

      <div class="form-inputs">
        <div class="input">
          <app-google (connect)="onSubmit('google')" />
        </div>
        <div class="input">
          <app-facebook (connect)="onSubmit('facebook')" />
        </div>
      </div>

      <app-button-loading
        [disabled]="loginForm.invalid && submitted"
        [loading]="loading"
        text="continue"
      />

    </form>

    @if (deactivatedAccount) {
      <p class="display-text-16px">
        {{ 'deactivated_account' | transloco }}
      </p>
    }

		<p class="last display-text-16px">
			Créer un compte avec votre e-mail ?
			<span class="cursor-pointer underline" (click)="handleRegister()">
				{{ 'subscribe' | transloco }}
			</span>
		</p>

		<input type="hidden" id="g-recaptcha-response" name="g-recaptcha-response" />
		<input type="hidden" name="action" value="validate_captcha" />
	</div>
</app-modal-container>
