import { APP_INITIALIZER } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { translocoConfig } from './transloco-config';

export const translocoPreLoad = {
  provide: APP_INITIALIZER,
  deps: [TranslocoService],
  multi: true,
  useFactory: (transloco: TranslocoService) => () => {
    const defaultLang = translocoConfig.defaultLang;
    transloco.setActiveLang(defaultLang);
    return transloco.load(defaultLang);
  }
};
