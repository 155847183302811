import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { FormPiecesFilters } from '@/models';

@Component({
  selector: 'app-pieces-filter',
  templateUrl: './pieces-filter.component.html',
  styleUrls: ['./pieces-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PiecesFilterComponent),
    multi: true
  }]
})
export class PiecesFilterComponent implements ControlValueAccessor {
  @Input() totalItems!: number;

  @Output() closed = new EventEmitter();
  @Output() validate = new EventEmitter();
  @Output() reinit = new EventEmitter<FormPiecesFilters>();

  public allRooms = ['1', '2', '3', '4', '5', '6p'] as const;
  public chambres: boolean[] = [];
  public pieces: boolean[] = [];
  public textChambres = '';
  public textPieces = '';
  public selectedChambres = 0;
  public selectedPieces = 0;

  private onChange: any = () => {};
  private onTouched: any = () => {};

  chambresChange(index: number, value: boolean): void {
    this.chambres[index] = value;
    this.updateCounters();
    this.update();
  }

  piecesChange(index: number, value: boolean): void {
    this.pieces[index] = value;
    this.updateCounters();
    this.update();
  }

  update(): void {
    this.onChange({
      nb_chambres: this.controlsToString(this.chambres),
      nb_pieces: this.controlsToString(this.pieces)
    });
    this.onTouched();
  }

  handleReset(): void {
    this.reinit.emit({ nb_chambres: '', nb_pieces: '' });
  }

  handleSubmit(): void {
    this.validate.emit();
  }

  handleClose(): void {
    this.closed.emit();
  }

  writeValue(value: FormPiecesFilters): void {
    this.chambres = this.stringToControls(value.nb_chambres);
    this.pieces = this.stringToControls(value.nb_pieces);
    this.updateCounters();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Convert a values coma separated string into boolean array
   * @param str The input string
   * @returns The boolean array
   */
  private stringToControls(str: string): boolean[] {
    const list = str.split(',');
    const val = this.allRooms.map((value) => (list.indexOf(value) > -1));

    // If more values than
    if (list.length > this.allRooms.length) {
      val[this.allRooms.length - 1] = true;
    }

    return val;
  }

  /**
   * Convert a boolean array into a string of coma separated values
   * @param values The boolean array
   * @param overflow true if we must fill values after 6 (for url param)
   * @returns The values string
   */
  private controlsToString(values: boolean[], overflow = true): string {
    let str = values.map((value, i) => value ? i + 1 : 0).filter((value) => !!value).join(',');

    if (overflow && values[5]) {
      for (let i = 7; i <= 20; i++) {
        str += ',' + i;
      }
    }

    return str;
  }

  private updateCounters(): void {
    this.selectedChambres = this.chambres.filter((n) => n).length;
    this.selectedPieces = this.pieces.filter((n) => n).length;

    this.textChambres = this.controlsToString(this.chambres, false);
    this.textPieces = this.controlsToString(this.pieces, false);
  }
}
