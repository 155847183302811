import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { FilterService } from '@/services/filter.service';
import { AuthService } from '@/services/auth.service';

@Component({
  selector: 'app-modal-active-alert',
  templateUrl: './modal-active-alert.component.html',
  styleUrls: ['./modal-active-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalActiveAlertComponent implements OnDestroy {
  @Input() connected = false;

  @Output() closeActiveAlert = new EventEmitter();
  @Output() openLogin = new EventEmitter();

  private subscriptions = new Subscription();
  public isLoading = false;

  constructor(
    private authService: AuthService,
    private filterService: FilterService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  activeAlert() {
    this.isLoading = true;
    this.subscriptions.add(
      this.authService.addAnnoncesSearchToUserAccount(this.filterService.filters).subscribe(() => {
        this.onCloseActiveAlert();
        this.isLoading = false;
      })
    );
  }

  onOpenLogin() {
    this.openLogin.emit();
  }

  onCloseActiveAlert() {
    this.closeActiveAlert.emit();
  }
}
