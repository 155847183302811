import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { FooterItem } from '@/models';

@Component({
  selector: 'app-footer-column',
  templateUrl: './footer-column.component.html',
  styleUrl: './footer-column.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterColumnComponent {
  @Input() type: 'nav' | 'faq' = 'nav';
  @Input() colTitle?: string;
  @Input() items?: FooterItem[];
}
