<app-modal-container>
  <div class="modal-reset">

    @if (done) {

      <div>
        <div class="title">
          <h3 class="display-h3-28px">{{"success update password" | transloco }}</h3>
          <p (click)="handleClose()"><span class="icon-close"></span></p>
        </div>
        <div class="text">
          <p class="display-text-16px mb-30px">{{"allow to login again" | transloco }}</p>
        </div>

        <app-button text="connect to era" size="large" (click)="goToLogin()" />
      </div>

    } @else {

      <form [formGroup]="resetForm" (submit)="onSubmit()">
        <div class="title">
          <h3 class="display-h3-28px">{{"define new password" | transloco }}</h3>
          <p (click)="handleClose()"><span class="icon-close"></span></p>
        </div>

        <app-form-input
          type="password"
          id="password"
          label="password"
          formControlName="password"
          [validationErrors]="submitted"
          [passwordTips]="true"
        />

        <app-form-input
          type="password"
          id="confirmation"
          label="confirm password"
          formControlName="confirmation"
          [validationErrors]="submitted"
        />

        <app-button-loading
          [disabled]="resetForm.invalid && submitted"
          [loading]="loading"
          text="modify password"
        />
      </form>

    }

  </div>
</app-modal-container>
