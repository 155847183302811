<app-filter-dropdown
	buttonTitle="criteria"
	iconName="icon-list"
	[filterSet]="!!buttonText"
	[totalItems]="totalItems"
	[contentScroll]="true"
	(filterSubmit)="handleSubmit()"
	(filterReset)="handleReset()"
	(filterClose)="handleClose()"
>
	<span filterButton>
		@if (buttonText) {
			{{ buttonText | transloco | titlecase }}
			@if (selectedCount > 1) {
				<span class="filterTypePlus display-text-12px">
					+{{ selectedCount - 1 }}
				</span>
			}
		}
	</span>

	<div filterContent>
		<div class="elements top">
			@for (t of CRITERES | slice:0:11; track t.key; let i = $index) {
				<label for="{{t.key}}" class="checkbox">
					<input
						[id]="t.key"
						class="hidden"
						type="checkbox"
						[ngModel]="criteres[i]"
						(ngModelChange)="critereChange(i, $event)"
					/>
					<span class="icon-check {{ criteres[i] ? 'active-checkbox' : 'unactive-checkbox' }}"></span>
					<span class="text">{{ t.key | transloco }}</span>
				</label>
			}
		</div>

		<span class="modal-dropdown-text">
			{{ 'type_achat' | transloco }}
		</span>
		<div class="elements bottom">
			@for (t of CRITERES | slice:-2; track t.key; let i = $index) {
				<label for="{{t.key}}" class="checkbox">
					@let j = CRITERES.length - 2 + i;
					<input
						[id]="t.key"
						class="hidden"
						type="checkbox"
						[ngModel]="criteres[j]"
						(ngModelChange)="critereChange(j, $event)"
					/>
					<span class="icon-check {{ criteres[j] ? 'active-checkbox' : 'unactive-checkbox' }}"></span>
					<span class="text">{{ t.key | transloco }}</span>
				</label>
			}
		</div>

	</div>

</app-filter-dropdown>
